import Lottie from 'lottie-react';
import React from 'react';
import styles from "./Call.module.css"
const Call = () => {
    return (
      <div className={styles.callStyle}>
        <div className={styles.animation}>
        </div>
        <span>+8801558607039</span>
      </div>
    );
};

export default Call;