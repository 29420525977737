import React from "react";
import About from "../Aboutus/About";
import Call from "../call/Call";
import Footer from "../Footer/Footer";
import Founder from "../Founders/Founder";
import Hero from "../Hero/Hero";
import Navigation from "../Navigation/Navigation";
import OurClient from "../OurCustomers/OurClient";
import OurProducts from "../OurProducts/OurProducts";
import OurProjects from "../OurProject/OurProjects";
import OurServices from "../OurService/OurServices";
import Partners from "../Partners/Partners";
import styles from "./HomePage.module.css"
const HomePage = () => {
  return (
    <>
      {/* Navigation Bar */}
      <Navigation />
      <Call/>
      {/* Hero Section */}
      <Hero />
      <About />
      <Founder />
      <OurProducts />
      <OurServices />
      <Partners />
      <OurProjects />
      <OurClient />
      <Footer/>
    </>
  );
};

export default HomePage;
