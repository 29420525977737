import Lottie from "lottie-react";
import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import aboutAnimation from "../../assets/animations/about-animation.json";
import styles from "./About.module.css";
const About = () => {
  return (
    <div className={styles.about_wrapper} id="about">
      <div className="d-flex justify-content-center ">
        <h2 className="text-center display-4 ">Our Mission & Vision</h2>
        <Lottie animationData={aboutAnimation} className={styles.animation} />
      </div>

      <Container className="py-5">
        <Row>
          <Col lg={6}>
            <img
              src="/images/about_us.jpg"
              alt=""
              srcset=""
              className="img-fluid"
            />
          </Col>
          <Col lg={6} className={styles.about_right}>
            <h3 className="webName d-inline-block  fw-bold">
              Mach Power Engineering
            </h3>
            <p className="fs-4 text-justify">
              {/*   Our mission is to be a leading regional manufacturer of electric
              switchgear by providing product with the highest level of
              technical excellence and reliability, coupled with responsive,
              effective, and flexible services to ensure total customer
              satisfaction. */}
              Our mission is to become a leading regional manufacturer and
              supplier of electrical products as well as parts for switches and
              other devices with the highest level of technical excellence and
              reliability, coupled with responsive, effective, and flexible
              services that ensure total customer satisfaction. We shall remain
              the benchmark in the electrical solutions industry by being an
              innovative and technology-driven company that will consistently
              deliver the world’s best products, ensuring better living
              standards for people through the continuous evolution of
              value-added services provided by a highly professional, dynamic,
              and committed team.
            </p>
        
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default About;
