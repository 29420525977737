import React from "react";
import { Card, Col, Container, Row } from "react-bootstrap";
import styles from "./Products.module.css"
const OurProducts = () => {
  return (
    <div>
      <Container className="pb-5" id="products">
        <h2 className="display-4 text-center pb-4">Our Products</h2>
        <Row xs={1} md={3} className="g-2">
          <Col className={styles.singleCard}>
            <Card>
              {/* <Card.Img variant="top" src="/images/p1.png" width={200}/>
               */}
              <div className="d-flex justify-content-center">
                {" "}
                <img src="/images/p1.png" alt="" srcset="" width={300} />
              </div>

              <Card.Body>
                <Card.Text>
                  <h5 className="text-center">
                    Oil Type Distribution Transformer
                  </h5>
                </Card.Text>
              </Card.Body>
            </Card>
          </Col>
          <Col className={styles.singleCard}>
            <Card>
              {/* <Card.Img variant="top" src="/images/p1.png" width={200}/>
               */}
              <div className="d-flex justify-content-center">
                {" "}
                <img src="/images/p2.png" alt="" srcset="" width={300} />
              </div>

              <Card.Body>
                <Card.Text>
                  <h5 className="text-center">Metering CT & PT</h5>
                </Card.Text>
              </Card.Body>
            </Card>
          </Col>
          <Col className={styles.singleCard}>
            <Card>
              {/* <Card.Img variant="top" src="/images/p1.png" width={200}/>
               */}
              <div className="d-flex justify-content-center">
                {" "}
                <img src="/images/p3.png" alt="" srcset="" width={300} />
              </div>

              <Card.Body>
                <Card.Text>
                  <h5 className="text-center">HT Switchgear</h5>
                </Card.Text>
              </Card.Body>
            </Card>
          </Col>
          <Col className={styles.singleCard}>
            <Card>
              {/* <Card.Img variant="top" src="/images/p1.png" width={200}/>
               */}
              <div className="d-flex justify-content-center">
                {" "}
                <img src="/images/p4.png" alt="" srcset="" width={300} />
              </div>

              <Card.Body>
                <Card.Text>
                  <h5 className="text-center">LT Switchgear</h5>
                </Card.Text>
              </Card.Body>
            </Card>
          </Col>
          <Col className={styles.singleCard}>
            <Card>
              {/* <Card.Img variant="top" src="/images/p1.png" width={200}/>
               */}
              <div className="d-flex justify-content-center">
                {" "}
                <img src="/images/p5.png" alt="" srcset="" width={300} />
              </div>

              <Card.Body>
                <Card.Text>
                  <h5 className="text-center">Busbar Trunking System</h5>
                </Card.Text>
              </Card.Body>
            </Card>
          </Col>
          <Col className={styles.singleCard}>
            <Card>
              {/* <Card.Img variant="top" src="/images/p1.png" width={200}/>
               */}
              <div className="d-flex justify-content-center">
                {" "}
                <img src="/images/p6.png" alt="" srcset="" width={300} />
              </div>

              <Card.Body>
                <Card.Text>
                  <h5 className="text-center">Fault Sensing Indicator</h5>
                </Card.Text>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default OurProducts;
