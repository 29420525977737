import React from "react";
import { Col, Row } from "react-bootstrap";
import styles from "./Founder.module.css";
const Founder = () => {
  return (
    <div className={styles.founder_wrapper}>
      <Row
        className={`g-0 w-100 p-5 d-flex align-items-center ${styles.founder_row}`}
      >
        <Col md={6} className={styles.founder_left}>
          <h2 className="display-4">Founder's Massage</h2>
          <p className="fs-4">
            Our goal is to exceed our customers’ expectations. We are focused on
            continuous improvement in products and services. We aspire to be a
            leading company producing and supplying high-quality power
            distribution products for our customers. We will continue to be a
            leader in the country's power sector. We always provide a safe
            environment for our employees and reward them with exceptional
            opportunities for professional growth.
          </p>
        </Col>
        <Col md={6} className={styles.founder_right}>
          <div className="d-flex justify-content-center">
            <img
              src="/images/photo2.png"
              alt=""
              srcset=""
              className="img-fluid"
              width={300}
            />
          </div>
          <p className="fs-4 text-center">
            <span className="text-center ">
              ENGINEER MD. MAMUNUR RASHID{" "}
            </span>{" "} <br />
            CHIEF EXECUTIVE OFFICER <br /> MECH POWER ENGINEERING AND VARIOUS
            ORGANIZATIONS UNDER THE MECH POWER UMBRELLA
          </p>
        </Col>
      </Row>
    </div>
  );
};

export default Founder;
