import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import Social from "../Social/Social";
import styles from "./Footer.module.css";
const Footer = () => {
  return (
    <div className={styles.footerWrapper}>
      <div className={styles.content}>
        <Container className="py-5" id="contact">
          <Row>
            <Col lg={4}>
              <div className="d-flex justify-content-center">
                {" "}
                <img src="/images/logo.png" alt="" srcset="" width={100} />
              </div>
              <h3 className="webName d-inline-block  fw-bold">
                Mach Power Engineering
              </h3>
              <p className="text-white ps-2 ">
                As Mech Power is more of solution provider, we make sure that
                our manufactured products meet the clients’ specific
                requirements. For making it certain, every department must work
                together. Highest level of quality can only be ensured through
                recruiting and motivating the right person.
              </p>
            </Col>
            <Col lg={4} className={styles.mid}>
              <h4 className="text-center text-white pt-5">Follow us On</h4>
              <div className="d-flex justify-content-center">
                <Social></Social>
              </div>
            </Col>
            <Col lg={4}>
              <h4 className="text-center text-white pt-5">Contact</h4>
              <p className="text-center text-white  ps-4">
                Address: House NO #08,Road NO#06, PC Culture Housing{" "}
                Shakartec,MohammadPur,Dhaka-1207,Bangladesh
              </p>
              <div className="d-flex justify-content-center">
                {" "}
                <img
                  src="/images/gmail.png"
                  alt=""
                  srcset=""
                  width={40}
                  className="ms-3"
                />
                <a href="">
                  {" "}
                  <span className="text-white">mechpowerengbd@gmail.com</span>
                </a>
              </div>
            </Col>
          </Row>
          <Row>
            <Col className="text-center text-white">
              All right reserved by mech power engineering @2022-2023
            </Col>
          </Row>
        </Container>
      </div>
    </div>
  );
};

export default Footer;
