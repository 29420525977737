import React from "react";
import { Card } from "react-bootstrap";
import Slider from "react-slick";
import styles from "./OurProjects.module.css";
const OurProjects = () => {
  const data = [
    {
      img: "/images/sp1.png",
      text: `SUPPLY, INSTALLATION AND COMMISSIONING OF ELECTRICAL EQUIPMENT AT CENTER FOR ENERGY RESEARCH (CER), UNITED INTERNATIONAL UNIVERSITY (UIU), UNITED CITY, MADANI AVENUE DHAKA-1212.`,
    },
    {
      img: "/images/sp2.png",
      text: `SUPPLY, INSTALLATION, TESTING & COMMISSIONING OF 01(ONE) NO. 33/0.415 KV 200 KVA AUXILIARY TRANSFORMER AT GOALPARA GRID SUB-STATION UNDER GMD, KHULNA (NORTH), PGCB.
`,
    },
    {
      img: "/images/sp3.png",
      text: `SUPPLY, INSTALLATION & COMMISSIONING OF SAIDI SAIFI AUTOMATION AND INTERRUPTION MONITORING SYSTEM UNDER NESCO.`,
    },
    {
      img: "/images/sp4.png",
      text: `SUPPLY, INSTALLATION, TESTING & COMMISSIONING OF REAL TIME SAIDI, SAIFI AND INTERRUPTION MONITORING SYSTEM AT RAJSHAHI CITY CENTER SUB-STATION UNDER NESCO.`,
    },
    {
      img: "/images/sp5.png",
      text: `SUPPLY, DELIVERY, INSTALLATION, TESTING & COMMISSIONING OF 11/0.4KV, 2 MVA SUB-STATION EQUIPMENT AT LIBAS TEXTILE LIMITED, NISCHINTAPUR, SHAFIPUR, GAZIPUR.`,
    },
    {
      img: "/images/sp6.png",
      text: `SUCCESSFULLY TESTING & COMMISSIONING OF OVER CURRENT & EARTH FAULT PROTECTION RELAY (SIEMENS ARGUS 7SR1102) AT 33/11KV, FARIDPUR AIS TYPE GRID SUBSTATION UNDER PABNA PALLI BIDDYUT SAMITY-1, BANGLADESH RURAL ELECTRIFICATION BOARD (BREB).`,
    },
    {
      img: "/images/sp7.png",
      text: `SUPPLY, INSTALLATION, TESTING & COMMISSIONING OF ELECTRICAL EQUIPMENT FOR 11/0.4KV, 800KVA SUB-STATIONT AT KARNAJHARA TEA FACTORY, TETULIA, PANCHAGORE.`,
    },
    {
      img: "/images/sp8.png",
      text: `SUPPLY, INSTALLATION, TESTING & COMMISSIONING OF ELECTRICAL EQUIPMENT FOR 11/0.4KV, 1 MVA SUB-STATION & SYNCH PANEL AT ESSENTIAL CLOTHING LTD. AKULICHALA, SHAKASHOR, KALIAKOIR, GAZIPUR.`,
    },
    {
      img: "/images/sp9.png",
      text: `SUPPLY, INSTALLATION, TESTING & COMMISSIONING OF OF ELECTRICAL EQUIPMENT FOR 11/0.4KV, 1 MVA SUB-STATION & SYNCH PANEL AT APEX TEXTILE PRINTING MILLS LIMITED, PALLIBIDDUT, SHAFIPUR KALIAKOIR, GAZIPUR.`,
    },
    {
      img: "/images/sp10.png",
      text: `SUPPLY, INSTALLATION, TESTING AND COMMISSIONING OF 1 KW OFF-GRID SOLAR POWER SYSTEM WITH MPPT REMOTE & ONLINE MONITORING SYSTEM AT AGARGAON 230/132/33KV GRID SUB-STATION UNDER GMD, DHAKA (NORTH-WEST), PGCB.`,
    },
    {
      img: "/images/sp11.png",
      text: `TESTING & COMMISSIONING OF 33/11KV SUB-STATION EQUIPMENT (4 MVA POWER TRANSFORMER, CT, PT, HT VCB, LA), 11/3.3KV (2.5 MVA DISTRIBUTION TRANSFORMER,  HT VCB)AT PEARL PAPER AND BOARD MILLS LTD., SHREERAMPUR, DHAMRAI, DHAKA.`,
    },
  ];
  const settings = {
    // dots: true,
    autoplay: true,
    autoplaySpeed: 2000,
    infinite: true,
    speed: 1000,
    slidesToShow: 3,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: true,
        //   dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        //   initialSlide: 2,
        },
      },
      {
        breakpoint: 575,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
    // style={{ height: "60rem" }}
  return (
    <div className={`${styles.OurProjects_wrapper}`}>
          <div className="container">
              <h2 className="display-4 text-center pt-4">Our projects</h2>
        <Slider {...settings}>
          {data.map((project) => (
            <div className="p-3">
              <Card >
                <Card.Img variant="top" src={`${project.img}`} />
                <Card.Footer>
                  <Card.Text>{project.text}</Card.Text>
                </Card.Footer>
              </Card>
            </div>
          ))}
        </Slider>
      </div>
    </div>
  );
};

export default OurProjects;
