import Lottie from "lottie-react";
import React from "react";
import { Container } from "react-bootstrap";
import serviceAnimation from "../../assets/animations/service.json";
import styles from "./Service.module.css";
const OurServices = () => {
  return (
    <div className={`pb-5 ${styles.services_wrapper}`} id="services">
      <div className="d-flex justify-content-center pt-5">
        <Lottie
          animationData={serviceAnimation}
          className={styles.serviceAnim}
        />
      </div>
      <h2 className="display-4  text-center">Our Services</h2>
      <Container>
        <div className="pb-5 pt-3">
          <div className="d-flex justify-content-around row ">
            <div className={`text-center ${styles.singleService} col-md-4`}>
              <img src="/images/s1.png" alt="" srcset="" width={50} />
              <h5>PROJECT DEVELOPMENT</h5>
              <p>Transmission Substation | Distribution Substation | Solar</p>
            </div>
            <div className={`text-center ${styles.singleService} col-md-4`}>
              <img src="/images/s2.png" alt="" srcset="" width={50} />
              <h5>GRID DEVELOPMENT</h5>
              <p>Substation | Transmission Line Underground Cable</p>
            </div>
            <div className={`text-center ${styles.singleService} col-md-4`}>
              <img src="/images/s3.png" alt="" srcset="" width={50} />
              <h5>MANUFACTURING</h5>
              <p>Electrical Products | Grid | Buildings | Garments</p>
            </div>
          </div>
          <div className="d-flex justify-content-around row">
            <div className={`text-center ${styles.singleService} col-md-4`}>
              <img src="/images/s4.png" alt="" srcset="" width={50} />
              <h5>SMART GRID SOLUTIONS</h5>
              <p>Smart Grid Infrastructure | Smart Grid Products</p>
            </div>
            <div className={`text-center ${styles.singleService} col-md-4`}>
              <img src="/images/s5.png" alt="" srcset="" width={50} />
              <h5>DESIGN ENGINEERING</h5>
              <p>
                Basic & Detailed Engineering | Owners Engineering Project
                Management
              </p>
            </div>
            {/*  <div className={`text-center ${styles.singleService} col-md-4`}>
              <img src="/images/s6.png" alt="" srcset="" width={50} />
              <h5>POWER GENERATION</h5>
              <p>Gas Fired | Oil Fired | Coal Fired | Solar |</p>
            </div> */}
            <div className={`text-center ${styles.singleService} col-md-4`}>
              <img src="/images/s7.png" alt="" srcset="" width={50} />
              <h5>REENGINEERING SERVICES</h5>
              <p>Transformer| Motor | Generator</p>
            </div>
          </div>
        </div>
      </Container>
    </div>
  );
};

export default OurServices;
