
import HomePage from './Components/HomePage/HomePage';

function App() {
  return (
    <>
    <HomePage/>
    </>
  );
}

export default App;
