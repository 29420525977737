import React from "react";
import { Container, Nav, Navbar } from "react-bootstrap";
import styles from "./Navigation.module.css";
const Navigation = () => {
  return (
    <div>
      <Navbar className={styles.navigationBar} expand="lg">
        <Container>
          <Navbar.Brand href="#home" className="">
            <img
              src="/images/logo.png"
              width={80}
              alt=""
              srcset=""
              className="d-inline-block"
            />
            <h3
              className={`webName d-inline-block  fw-bold ${styles.BrandName}`}
            >
              Mach Power Engineering
            </h3>
            {/* <img src="/images/name.png" width={250} alt="" srcset="" className="d-inline-block"/> */}
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="align-items-end mx-auto">
              <Nav.Link href="#home" className="text-white">
                Home
              </Nav.Link>
              <Nav.Link href="#about" className="text-white">
                About
              </Nav.Link>
              <Nav.Link href="#products" className="text-white">
                Products
              </Nav.Link>
              <Nav.Link href="#services" className="text-white">
                Services
              </Nav.Link>
              <Nav.Link href="#contact" className="text-white">
                Contact
              </Nav.Link>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </div>
  );
};

export default Navigation;
