import React from "react";
import { Container } from "react-bootstrap";
import styles from "./Social.module.css";
const Social = () => {
  return (
    <div>
      <Container className={styles.socialWrapper}>
        <a
          href="https://www.facebook.com/mechpowerengbd"
          target="_blank"
          rel="noopener noreferrer"
          className="mx-2 pt-4"
        >
          <img src="/images/facebook.png" alt="" srcset="" width={30} />
        </a>

        <a
          href="https://www.facebook.com/mechpowerengbd"
          target="_blank"
          className="mx-2 pt-4"
          rel="noopener noreferrer"
        >
          <img src="/images/instagram.png" alt="" srcset="" width={30} />
        </a>

        <a
          href="https://www.facebook.com/mechpowerengbd"
          target="_blank"
          className="mx-2 pt-4"
          rel="noopener noreferrer"
        >
          <img src="/images/linkedin.png" alt="" srcset="" width={30} />
        </a>
        <a
          href="https://wa.me/01558607039"
          target="_blank"
          className="mx-2 pt-4"
          rel="noopener noreferrer"
        >
          <img src="/images/wtsap.png" alt="" srcset="" width={37} />
        </a>
      </Container>
    </div>
  );
};

export default Social;
