import React from "react";
import { Container } from "react-bootstrap";
import styles from "./OurClient.module.css";
const OurClient = () => {
  const photos = [
    {
      src: "/images/c1.png",
    },
    {
      src: "/images/c2.png",
    },
    {
      src: "/images/c3.png",
    },
    {
      src: "/images/c4.png",
    },
    {
      src: "/images/c5.png",
    },
    {
      src: "/images/c6.png",
    },
    {
      src: "/images/c7.png",
    },
    {
      src: "/images/c8.png",
    },
    {
      src: "/images/c9.png",
    },
    {
      src: "/images/C10.png",
    },
    {
      src: "/images/C11.png",
    },
    {
      src: "/images/C12.png",
    },
    {
      src: "/images/C13.png",
    },
    {
      src: "/images/C14.png",
    },
    {
      src: "/images/C15.png",
    },
    {
      src: "/images/C16.png",
    },
    {
      src: "/images/C17.png",
    },
    {
      src: "/images/C18.png",
    },
    {
      src: "/images/C19.png",
    },
    {
      src: "/images/C20.png",
    },
    {
      src: "/images/C21.png",
    },
    {
      src: "/images/C22.png",
    },
    {
      src: "/images/C23.png",
    },
    {
      src: "/images/C24.png",
    },
   
  ];
  return (
    <div className={`py-5 w-100`}>
      <h2 className="display-4 text-center py-5">Our Clients</h2>

      <Container className={`pb-5 `}>
        <div className={`${styles.photo_container}`}>
          {photos.map((p) => (
            <img
              src={`${p.src}`}
              alt=""
              srcset=""
              className={`m-1 ${styles.singleImg}`}
            />
          ))}
        </div>
      </Container>
    </div>
  );
};

export default OurClient;
