import React from "react";
import Slider from "react-slick";
import Lottie from "lottie-react";
import styles from "./Partner.module.css";
import partners from "../../assets/animations/partnerAnimation.json"
import { Container } from "react-bootstrap";
const Partners = () => {
  const settings = {
    //    dots: true,
    autoplay: true,
    autoplaySpeed: 2000,
    infinite: true,
    speed: 1000,
    slidesToShow: 7,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 1,
          infinite: true,
        //   dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 3,
            slidesToScroll: 1,
          infinite:true,
        //   initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
          settings: {
            infinite:true,
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
    ],
  };
  return (
    <div className={`d-flex justify-content-center ${styles.partners_wrapper} `}>
      <div className=" g-0 w-100">
        <h3 className="display-4 pt-5 text-center">Our Partners</h3>
        <div className="d-flex justify-content-center">
          <Lottie
            animationData={partners}
            className={styles.partnersAnimation}
          />
        </div>
        <div className={styles.allSliders}>
          <Slider {...settings} className=" ">
            <div className="d-flex justify-content-center ">
              <img
                src="/images/pp1.png"
                alt=""
                className="img-fluid"
                // width={100}
              />
            </div>
            <div className="d-flex justify-content-center ">
              <img
                src="/images/pp2.png"
                alt=""
                className="img-fluid"
                // width={100}
              />
            </div>
            <div className="d-flex justify-content-center ">
              <img
                src="/images/pp3.png"
                alt=""
                className="img-fluid"
                // width={100}
              />
            </div>
            <div className="d-flex justify-content-center">
              <img
                src="/images/pp4.png"
                alt=""
                className="img-fluid"
                // width={100}
              />
            </div>
            <div className="d-flex justify-content-center ">
              <img
                src="/images/pp5.png"
                alt=""
                className="img-fluid"
                // width={100}
              />
            </div>
            <div className="d-flex justify-content-center ">
              <img
                src="/images/pp6.png"
                alt=""
                className="img-fluid"
                // width={100}
              />
            </div>
            <div className="d-flex justify-content-center ">
              <img
                src="/images/pp7.png"
                alt=""
                className="img-fluid"
                // width={100}
              />
            </div>
            <div className="d-flex justify-content-center ">
              <img
                src="/images/pp8.png"
                alt=""
                className="img-fluid"
                // width={100}
              />
            </div>
            <div className="d-flex justify-content-center">
              <img
                src="/images/pp9.png"
                alt=""
                className="img-fluid"
                // width={100}
              />
            </div>
            <div className="d-flex justify-content-center ">
              <img
                src="/images/pp10.png"
                alt=""
                className="img-fluid"
                // width={100}
              />
            </div>
            <div className="d-flex justify-content-center p-1">
              <img src="/images/pp11.png" alt="" />
            </div>
            <div className="d-flex justify-content-center p-1">
              <img src="/images/pp12.png" alt="" className="img-fluid" />
            </div>
          </Slider>
        </div>
      </div>
    </div>
  );
};

export default Partners;
