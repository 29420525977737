import React from "react";
import { Col, Container, Nav, Row } from "react-bootstrap";
import Slider from "react-slick";
import Social from "../Social/Social";
import styles from "./Hero.module.css";
const Sliders = () => {
  const settings = {
    dots: true,
    autoplay: true,
    autoplaySpeed: 10000,
    infinite: true,
    speed: 2000,
    slidesToShow: 1,
    slidesToScroll: 1,
    pauseOnHover: true,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          //   dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          //   initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          dots: false,
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  return (
    <div className={styles.carousels}>
      <Container>
        <div>
          <Slider {...settings}>
            <div>
              <Row className={`g-0 ${styles.slideRow}`}>
                <Col lg={6}>
                  <img
                    src="/images/1pp.jpg"
                    alt=""
                    className={`img-fluid ${styles.sliderImg}`}
                  />
                </Col>
                <Col lg={6} className={`p-md-5 px-5  ${styles.slideCom1}`}>
                  <h3 className={`webName fw-bold `}>Mach Power Engineering</h3>
                  <div className={styles.sl1}>
                    <p
                      className={`info text-white  px-3 pt-4 ${styles.slideCom1Text}`}
                    >
                      We are one of the market leaders in Bangladesh to
                      manufacture Oil Type Distribution Transformers, Single
                      Phase Transformer, Instrument Transformer, Busbar Trunking
                      systems, Control & Relay Panel, Indoor VCB Panel etc
                      distribution systems to high impact industries.
                    </p>
                    <button className={`btn  mx-2 ${styles.welcomeBtn}`}>
                      <Nav.Link href="#contact" className="text-white">
                        Contact with us
                      </Nav.Link>
                    </button>
                    <Social />
                    <br />
                  </div>
                </Col>
              </Row>
            </div>
            <div>
              <Row className={`g-0 ${styles.slideRow}`}>
                <Col lg={6}>
                  <img
                    src="/images/solar.jpg"
                    alt=""
                    srcset=""
                    className={`img-fluid ${styles.sliderImg}`}
                  />
                </Col>
                <Col lg={6} className={`p-md-5 px-5  ${styles.slideCom}`}>
                  <h2 className={`text-white display-5 ${styles.heading}`}>
                    Solar Energy Solutions OFF-GRID / ON-GRID
                  </h2>
                  <div className={styles.sl2}>
                    <p className="info text-white fs-6 px-3">
                      We Have The Engineering Skill Set To Design And Supply A
                      Turn-Key Solution As Well As The Practical Experience To
                      Meet All Your PV Based Solar Power Needs. Along With Our
                      Local And Global Partners, We Strive To Find Most
                      Cost-Effective Yet Customized Solutions For Our Customers.
                    </p>
                    <button className={`btn  mx-2 ${styles.welcomeBtn}`}>
                      <Nav.Link href="#contact" className="text-white">
                        Contact with us
                      </Nav.Link>
                    </button>
                  </div>
                </Col>
              </Row>
            </div>
            <div>
              <Row className={`g-0 ${styles.slideRow}`}>
                <Col lg={6} className={`p-md-5 px-5  ${styles.slideCom}`}>
                  <h2 className={`text-white display-5 ${styles.heading}`}>
                    BBT - Busbar Trunking System POWER + LIGHTING
                  </h2>
                  <div className={styles.sl3}>
                    <p className="info text-white fs-6 px-3">
                      BBT Is A Cost-Effective Alternative To Cable Which Is
                      Efficient, Safe, Flexible And Long-Lasting. We Offer A
                      Wide Range Of Copper And Aluminum BBT From 25Amps To
                      6300Amps.
                    </p>
                    <button className={`btn  mx-2 ${styles.welcomeBtn}`}>
                      <Nav.Link href="#contact" className="text-white">
                        Contact with us
                      </Nav.Link>
                    </button>
                  </div>
                </Col>
                <Col lg={6}>
                  <img
                    src="/images/busbar.jpg"
                    alt=""
                    srcset=""
                    className={`img-fluid ${styles.sliderImg}`}
                  />
                </Col>
              </Row>
            </div>
            <div>
              <Row className={`g-0 ${styles.slideRow}`}>
                <Col lg={6} className={`p-5 px-5  ${styles.slideCom}`}>
                  <h2 className={`text-white display-5 ${styles.heading3}`}>
                    Power Transformers
                  </h2>
                  <div className={styles.sl4}>
                    <p className="info text-white fs-6 px-3">
                      Discover Our Wide Range Of Oil-Immersed Power
                      Transformers. Our Long Experience With 33kV Substation
                      Equipment Include: Manufacturing, Supply, Installation,
                      Testing And Commissioning.
                    </p>
                    <button className={`btn  mx-2 ${styles.welcomeBtn}`}>
                      <Nav.Link href="#contact" className="text-white">
                        Contact with us
                      </Nav.Link>
                    </button>
                  </div>
                </Col>
                <Col lg={6}>
                  <img
                    src="/images/power.jpg"
                    alt=""
                    srcset=""
                    className={`img-fluid ${styles.sliderImg}`}
                  />
                </Col>
              </Row>
            </div>
          </Slider>
        </div>
      </Container>
    </div>
  );
};

export default Sliders;
