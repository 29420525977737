import React from 'react';
import Slider from 'react-slick';
import styles from "./Hero.module.css"
import Sliders from './Sliders';

const Hero = () => {
    return (
      <>
        <div className={styles.black_glass} id="home"></div>
        <div className={` ${styles.sliderWrapper}`}>
          <video src="/images/video3.mp4" autoPlay loop muted />
          <div className={styles.slider_section}>
            <Sliders />
          </div>
        </div>
      </>
    );
};

export default Hero;